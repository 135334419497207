var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: `fill-current  text-${_vm.color}`,
    attrs: {
      "height": `${_vm.height}px`,
      "width": `${_vm.width}px`,
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M1.26103 12.9058L4.73269 16.3775C5.20151 16.8461 5.83728 17.1094 6.50019 17.1094C7.1631 17.1094 7.79887 16.8461 8.26769 16.3775L18.9894 5.65579C19.1412 5.49862 19.2252 5.28812 19.2233 5.06962C19.2214 4.85112 19.1337 4.64211 18.9792 4.48761C18.8247 4.3331 18.6157 4.24546 18.3972 4.24356C18.1787 4.24166 17.9682 4.32566 17.811 4.47746L7.08936 15.1991C6.93309 15.3554 6.72116 15.4431 6.50019 15.4431C6.27922 15.4431 6.0673 15.3554 5.91103 15.1991L2.43936 11.7275C2.28219 11.5757 2.07169 11.4917 1.85319 11.4936C1.63469 11.4955 1.42568 11.5831 1.27118 11.7376C1.11667 11.8921 1.02903 12.1011 1.02713 12.3196C1.02523 12.5381 1.10923 12.7486 1.26103 12.9058Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }